<template>
  <div class="finance-tracker-table">
    <el-table
      :data="isLoading ? [] : ads"
      border
      class="el-table-slim w-full no-header-ellipsis min-w-95"
      @sort-change="onSort"
    >
      <template #empty>
        <table-empty-slot
          :is-loading="isLoading"
          :has-data="!!ads"
          empty-message="No Ads found"
          error-message="There was a problem loading the ads. Please try again later."
        />
      </template>

      <el-table-column
        label="Ad Ref"
        align="top"
        sortable="custom"
        prop="ref"
        fixed="left"
        min-width="120"
      >
        <template v-slot="{ row: ad }">
          <finance-tracker-ad-column :ad="ad" />
        </template>
      </el-table-column>

      <el-table-column
        label="Ad Date"
        align="top"
        sortable="custom"
        prop="start_date"
        fixed="left"
        min-width="90"
      >
        <template v-slot="{ row: ad }">
          <date-column :start-date="ad.start_date" :end-date="ad.end_date" />
        </template>
      </el-table-column>

      <el-table-column
        label="Status"
        align="top"
        sortable="custom"
        prop="orderLineItem.status"
        min-width="180"
      >
        <template v-slot="{ row: ad }">
          <div class="overflow-hidden ellipsis max-w-full">
            <h4>Ad Status</h4>
            <status-icon
              :icons="AdStatusInfo"
              :status="ad.status"
              inline
              class="mt-1"
            />
          </div>
          <div class="mt-3">
            <h4>Line Item Status</h4>
            <status-icon
              :icons="OrderLineItemStatusIcons"
              :status="ad.orderLineItem.status"
              inline
              class="mt-1"
            />
          </div>
        </template>
      </el-table-column>

      <el-table-column
        label="Booking Type"
        align="top"
        sortable="custom"
        prop="orderLineItem.booking_type"
        min-width="120"
      >
        <template v-slot="{ row: ad }">
          {{ ad.orderLineItem.booking_type }}
        </template>
      </el-table-column>

      <el-table-column
        label="Supplier"
        align="top"
        sortable="custom"
        prop="supplier.display_name"
        min-width="120"
      >
        <template v-slot="{ row: ad }">
          <icon v-if="ad.supplier.is_test" :icon="testIcon" class="mr-1" />
          {{ ad.supplier.display_name }}
        </template>
      </el-table-column>

      <el-table-column
        label="Supplier Type"
        align="top"
        sortable="custom"
        prop="supplier.organization_type"
        width="120"
      >
        <template v-slot="{ row: ad }">
          {{ ad.supplier.organization_type }}
        </template>
      </el-table-column>

      <el-table-column
        label="Advertiser"
        align="top"
        sortable="custom"
        prop="buyer.name"
        min-width="120"
      >
        <template v-slot="{ row: ad }">
          {{ ad.buyer.display_name }}
        </template>
      </el-table-column>

      <el-table-column
        label="Campaign"
        align="top"
        sortable="custom"
        prop="campaign.name"
        min-width="120"
      >
        <template v-slot="{ row: ad }">
          <a :href="campaignNovaUrl(ad.campaign)" target="_blank">
            {{ ad.campaign.name }}
          </a>
        </template>
      </el-table-column>

      <el-table-column
        v-if="$feature('ft-invoices')"
        label="Gross Revenue"
        align="top"
        min-width="250"
        sortable="custom"
        prop="grossRevenuePeriodDue"
      >
        <template v-slot="{ row: ad }">
          <finance-tracker-gross-revenue-column
            :ad="ad"
            :billing-period="pager.filter.adDeliveryMonth"
            @refresh="$emit('refresh')"
          />
        </template>
      </el-table-column>

      <el-table-column
        label="Supplier Earnings"
        align="top"
        sortable="custom"
        min-width="250"
        prop="supplierEarningsPeriodDue"
      >
        <template v-slot="{ row: ad }">
          <finance-tracker-supplier-earnings-column
            :ad="ad"
            :billing-period="pager.filter.adDeliveryMonth"
            @refresh="$emit('refresh')"
          />
        </template>
      </el-table-column>

      <el-table-column
        label="Net Revenue ($)"
        align="top"
        min-width="140"
        sortable="custom"
        sort-by="`netRevenueDollars`"
        prop="netRevenueDollars"
      >
        <template v-slot="{ row: ad }">
          {{ ad.netRevenueDollars | currency }}
        </template>
      </el-table-column>

      <el-table-column
        label="Net Revenue (%)"
        align="top"
        min-width="140"
        sortable="custom"
        prop="netRevenuePercent"
      >
        <template v-slot="{ row: ad }">
          {{ ad.netRevenuePercent | percent }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import FinanceTrackerAdColumn from '@/components/Admin/Accounting/FinanceTracker/Columns/FinanceTrackerAdColumn';
import FinanceTrackerGrossRevenueColumn from '@/components/Admin/Accounting/FinanceTracker/Columns/FinanceTrackerGrossRevenueColumn';
import FinanceTrackerSupplierEarningsColumn from '@/components/Admin/Accounting/FinanceTracker/Columns/FinanceTrackerSupplierEarningsColumn';
import StatusIcon from '@/components/Core/Statuses/StatusIcon';
import DateColumn from '@/components/Core/Table/Columns/DateColumn';
import TableEmptySlot from '@/components/Core/Table/TableEmptySlot';
import { AdStatusInfo, OrderLineItemStatusIcons } from '@/constants';
import { lab as testIcon } from '@/vendor/icons';

export default {
  components: {
    StatusIcon,
    FinanceTrackerSupplierEarningsColumn,
    FinanceTrackerGrossRevenueColumn,
    DateColumn,
    FinanceTrackerAdColumn,
    TableEmptySlot
  },
  props: {
    isLoading: Boolean,
    pager: {
      type: Object,
      default: () => ({})
    },
    ads: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      testIcon,
      AdStatusInfo,
      OrderLineItemStatusIcons
    };
  },
  methods: {
    campaignNovaUrl(campaign) {
      return `${window._env.API_URL}/nova/resources/campaigns/${campaign.id}`;
    },
    onSort({ prop: column, order }) {
      switch (column) {
        case 'netRevenueDollars':
        case 'netRevenuePercent':
        case 'grossRevenuePeriodDue':
        case 'supplierEarningsPeriodDue':
          column = `\`${column}\``;
          break;
      }

      this.$emit('update:pager', {
        ...this.pager,
        sort: column && [{ column, order }]
      });
    }
  }
};
</script>
