var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-table',{ref:"table",staticClass:"el-table-slim w-full el-table-nested",attrs:{"border":"","data":_vm.isLoading ? [] : _vm.sortableRecords,"max-height":_vm.tableMaxHeight,"default-expand-all":""},on:{"selection-change":_vm.onSelect},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('table-empty-slot',{attrs:{"is-loading":_vm.isLoading,"has-data":!!_vm.records,"empty-message":"No Suppliers","error-message":"There was a problem loading the suppliers. Please try again."}})]},proxy:true}])},[_c('el-table-column',{attrs:{"fixed":"left","type":"selection","width":"40"}}),_c('el-table-column',{attrs:{"type":"expand"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var supplier = ref.row;
return [_c('finance-tracker-nested-medium-type-table',{attrs:{"records":supplier.mediumTypes}})]}}])}),_c('el-table-column',{attrs:{"sortable":"","label":"Supplier","prop":"supplierName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var supplier = ref.row;
return [(supplier.isTest)?_c('icon',{staticClass:"mr-1",attrs:{"icon":_vm.testIcon}}):_vm._e(),_vm._v(" "+_vm._s(supplier.supplierName)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Product Medium","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var supplier = ref.row;
return undefined}}])}),(_vm.$feature('ft-invoices'))?_c('el-table-column',{attrs:{"sortable":"","label":"Gross Revenue","prop":"grossRevenuePeriodDue","align":"right","width":"155"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var supplier = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("currency")(supplier.grossRevenuePeriodDue))+" ")]}}],null,false,805258272)}):_vm._e(),_c('el-table-column',{attrs:{"sortable":"","label":"Supplier Earnings","prop":"supplierEarningsPeriodDue","align":"right","width":"170"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var supplier = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("currency")(supplier.supplierEarningsPeriodDue))+" ")]}}])}),_c('el-table-column',{attrs:{"sortable":"","label":"Net Revenue ($)","prop":"netRevenueDollars","align":"right","width":"170"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var supplier = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("currency")(supplier.netRevenueDollars))+" ")]}}])}),_c('el-table-column',{attrs:{"sortable":"","label":"Net Revenue %","prop":"netRevenuePercent","align":"right","width":"170"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var supplier = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("percent")(supplier.netRevenuePercent))+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }