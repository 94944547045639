<template>
  <div class="flex flex-grow space-x-8">
    <finance-tracker-summary-item label="Will be sent" bold bar loose>
      <tween :value="count">
        <template v-slot="{ value }">
          {{ value | integer }} {{ category }}
        </template>
      </tween>
    </finance-tracker-summary-item>

    <finance-tracker-summary-item
      v-if="$feature('ft-invoices')"
      label="Gross Revenue"
      bar
      loose
    >
      <tween :value="grossRevenueTotal">
        <template v-slot="{ value }">
          {{ value | currency }}
        </template>
      </tween>
    </finance-tracker-summary-item>

    <finance-tracker-summary-item label="Supplier Earnings" bar loose>
      <tween :value="supplierEarningsTotal">
        <template v-slot="{ value }">
          {{ value | currency }}
        </template>
      </tween>
    </finance-tracker-summary-item>

    <finance-tracker-summary-item label="Net Revenue" loose>
      <tween :value="netRevenueTotal">
        <template v-slot="{ value }">
          {{ value | currency }}
        </template>
      </tween>
    </finance-tracker-summary-item>
  </div>
</template>
<script>
import FinanceTrackerSummaryItem from '@/components/Admin/Accounting/FinanceTracker/Summary/FinanceTrackerSummaryItem';
import { sumBy } from 'lodash';

export default {
  name: 'FinanceTrackerRecordsSummary',
  components: { FinanceTrackerSummaryItem },
  props: {
    records: {
      type: Array,
      required: true
    },
    category: {
      type: String,
      required: true,
      validator: value => ['Payments', 'Invoices'].indexOf(value) !== -1
    }
  },
  computed: {
    count() {
      return this.records?.length || 0;
    },
    grossRevenueTotal() {
      return sumBy(this.records, r => +r.grossRevenuePeriodDue);
    },
    supplierEarningsTotal() {
      return sumBy(this.records, r => +r.supplierEarningsPeriodDue);
    },
    netRevenueTotal() {
      return sumBy(this.records, r => +r.netRevenueDollars);
    }
  }
};
</script>
